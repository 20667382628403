import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Container from '~components/Container';

const useStyles = makeStyles({
  root: {
    backgroundColor: props => props.background_color,
    position: 'relative',
    paddingTop: '15px',
    paddingBottom: '15px'
  },
  image: {
    width: '100%',
    borderRadius: 16
  }
});

const ArticleContentImage = ({ image, background_color, containerSize }) => {
  const classes = useStyles({ background_color });

  return (
    <>
      <div className={classes.root}>
        <Container maxWidth={containerSize}>
          <img src={image?.url} alt={image?.alt} className={classes.image} />
        </Container>
      </div>
    </>
  );
};

ArticleContentImage.propTypes = {
  background_color: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string
  }).isRequired,
  containerSize: PropTypes.string
};

ArticleContentImage.defaultProps = {
  containerSize: 'md',
  background_color: '#FFFFFF'
};

export default ArticleContentImage;
